<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลสมาชิก
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เบอร์มือถือ</label>
                <input type="text" v-model="result.phone" class="form-control" placeholder="ข้อมูลเบอร์มือถือ">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Pin</label>
                <input type="text" v-model="result.password_pin" class="form-control" placeholder="รหัส Pin">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อ-นามสกุล</label>
                <input type="text" v-model="result.full_name" class="form-control" placeholder="ข้อมูลชื่อ-นามสกุล">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ธนาคาร</label>
                <b-form-select v-model="result.bank" :options="option_bank"/>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เลขบัญชี</label>
                <input type="text" v-model="result.bank_number" class="form-control" placeholder="ข้อมูลเลขบัญชี">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">ชื่อบัญชี</label>
                <input type="text" v-model="result.full_name" class="form-control" placeholder="ข้อมูลชื่อบัญชี">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Turn date</label>
                <input type="text" v-model="result.turn_date" class="form-control" placeholder="ข้อมูล Turn date ตัวอย่าง 2020-10-30">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Turn over before</label>
                <input type="text" v-model="result.turn_before" class="form-control" placeholder="ข้อมูล Turn before">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Turn over</label>
                <input type="text" v-model="result.turn_over" class="form-control" placeholder="ข้อมูล Turn over">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">สถานะ</label>
                <b-form-select v-model="result.role" :options="option_status"/>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">เอเย่นต์</label>
                <b-form-select v-model="result.agent" :options="option"/>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Commission Percent</label>
                <input type="text" v-model="result.commission_percent" class="form-control" placeholder="ข้อมูล Commission Percent">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">รับโบนัสคืนยอดเสีย</label>
                <b-form-select v-model="result.is_active_return_balance" :options="option"/>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">อยู่ภายใต้ยูส</label>
                <b-form-select v-model="result.username_ref" :options="option_ref"/>
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Line</label>
                <input type="text" v-model="result.line_id" class="form-control" placeholder="ข้อมูล Line">
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-card no-body v-if="result.agent == 1">
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลเอเย่นต์
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Username</label>
                <input type="text" v-model="result.agent_username" class="form-control" placeholder="ข้อมูล Username">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Password</label>
                <input type="text" v-model="result.agent_password" class="form-control" placeholder="ข้อมูล Password">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Role</label>
                <b-form-select v-model="result.agent_role" :options="option_role"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Prefix</label>
                <input type="text" v-model="result.agent_prefix" class="form-control" placeholder="ข้อมูล Prefix">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Commission</label>
                <input type="text" v-model="result.agent_commission" class="form-control" placeholder="Commission">
              </b-form-group>
            </b-col>
            <b-col cols="md-4">
              <b-form-group>
                <label class="control-label">Register User</label>
                <input type="text" v-model="result.register_user" class="form-control" placeholder="ข้อมูล Register User">
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>

    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลเกม
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-6">
              <b-form-group>
                <label class="control-label">Account Agent Username</label>
                <input type="text" v-model="result.account_agent_username" class="form-control" placeholder="Username" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-6">
              <b-form-group>
                <label class="control-label">Account Agent Password</label>
                <input type="text" v-model="result.account_agent_password" class="form-control" placeholder="Password">
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดท้าย -->
    <b-card no-body>
      <div class="card-content">
        <b-card-body>
          <h4 class="card-title">
            ข้อมูลยูสเซอร์
          </h4>
          <hr />
          <div class="mt-3" />
          <b-row>
            <b-col cols="md-6">
              <b-form-group>
                <label class="control-label">Username</label>
                <input type="text" v-model="result.username" class="form-control" placeholder="ข้อมูลเบอร์มือถือ" readonly>
              </b-form-group>
            </b-col>
            <b-col cols="md-6">
              <b-form-group>
                <label class="control-label">Password</label>
                <input type="text" v-model="result.password" class="form-control" placeholder="ข้อมูล Password">
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <div class="text-right m-b-10">
                  <router-link :to="PATH" class="btn bg-gradient-warning waves-effect waves-light mr-1"><span><feather-icon icon="ArrowLeftIcon" class="font-medium-1 mr-1" /></span>ย้อนกลับ</router-link>
                  <button @click="update()" type="submit" class="btn bg-gradient-success waves-effect waves-light text-white"><span><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" /></span>แก้ไข</button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BTable,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      PATH: '/user',
      BASE_URL: process.env.BASE_URL,
      API_GET_URL: 'user/user_update/' + this.$route.params.id,
      API_UPDATE_URL: 'user/user_update/' + this.$route.params.id,
      result: {
        phone: '',
        username: '',
        password_pin: '',
        full_name: '',
        bank: '',
        bank_number: '',
        turn_date: 0,
        turn_before: 0,
        turn_over: 0,
        role: 0,
        agent: 0,
        commission_percent: 0,
        is_active_return_balance: 0,
        username_ref: '',
        line_id: '',
        account_agent_username: '',
        account_agent_password: '',
      },
      option_role: [
        { text: 'เลือกตำแหน่ง', value: '' },
        { text: 'ซีเนียร์', value: 'pa' },
        { text: 'มาสเตอร์เอเย่นต์', value: 'mg' },
        { text: 'เอเย่นต์', value: 'ag' },
        { text: 'ซับเอเย่นต์', value: 'sa' },
      ],
      option_status: [
        { text: 'ผู้ดูแลระบบสูงสุด', value: '0' },
        { text: 'ผู้ดูแลระบบ', value: '1' },
        { text: 'สมาชิก', value: '2' },
        { text: 'พนักงาน', value: '3' },
      ],
      option_bank: [
        { text: 'ธนาคารกรุงเทพ จำกัด (มหาชน)', value: '01' },
        { text: 'ธนาคารกสิกรไทย จำกัด (มหาชน)', value: '02' },
        { text: 'ธนาคารกรุงไทย จำกัด (มหาชน)', value: '03' },
        { text: 'ธนาคารทหารไทยธนชาต จำกัด (มหาชน)', value: '04' },
        { text: 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)', value: '05' },
        { text: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)', value: '06' },
        { text: 'ธนาคารออมสิน จำกัด (มหาชน)', value: '07' },
        { text: 'ธนาคารธนชาติ จำกัด (มหาชน)', value: '08' },
        { text: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร จำกัด (มหาชน)', value: '09' },
      ],
      option_ref: [
        { text: 'ไม่มี', value: '' },
        { text: 'ผู้ดูแลระบบสูงสุด : admin', value: '1' },
      ],
      option: [
        { text: 'ไม่', value: '0' },
        { text: 'ใช่', value: '1' },
      ],
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    toMoney(value) {
      const parts = parseFloat(value).toFixed(2).split('.')
      return parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + (parts[1] ? `.${parts[1]}` : '')
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    success() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'แก้ไขสำเร็จแล้ว!',
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    fetch() {
      this.$http.get(this.API_GET_URL).then(res => {
        if (res.data != null && res.data.result != null) {
          this.result = res.data.result
        }
        else this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
    update() {
      this.loading()
      this.$http.post(this.API_UPDATE_URL, this.result).then(res=> {
        this.$swal.close()
        this.success()
      }).catch(()=>this.$router.push(this.PATH))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
